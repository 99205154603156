<template> 

  <!-- <div class="dsc" v-html="props.text"></div> -->

  <!-- {{list}} -->

  <div class="dsc" v-if="list?.length">

    <div class="title_desc">Frequently asked questions</div>

    <div class="txt">
      <UiExpand
        v-for="(item, key) in list"        
        :key="key"
        :name="item.title"
        :text="item.text"
        :is-expanded="true"
      />
    </div>
  </div>

</template>


<script setup lang="ts">
 
import { sliceQuestionAnswerTextRegex } from '@/types/other';

interface DescriptionProps {
  text: string;
}

const props = defineProps<DescriptionProps>();

const list = computed(() => {
  return sliceQuestionAnswerTextRegex(props.text);
});

</script>
 


<style scoped>
 
.dsc{
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 1100px;
  width: 100%;
}
 
.title_desc{
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  margin-top: 4rem;
}

.txt:deep(*){
  /* font-size: 1rem;
  line-height: 1.5; */
}
.txt:deep(h2){
  /* font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 1rem; */
}
.txt:deep(h3){
  margin-top: 0.5rem;
}
.txt:deep(h4){}
.txt:deep(p){
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.txt:deep(a){
  color: var(--un-firm);
  text-decoration: underline;
} 

.txt:deep(ul){
  padding-left: 1.6rem;
}

@container pb (max-width: 650px) {
   

}

</style>