<template>

  <div class="pb" v-if="strainData">

    <StrainCategories   
      :category-id="categoryId"
      :category-val="categoryVal"
      :is-category-selected="isCategorySelected"
      @select="handleCategoriesSelect"
    />

    <div class="headerow"> 

      <template v-if="categoryId && categoryVal">
        <h1>
          {{ categoryH1 }}
        </h1>        
        <NuxtLink to="/seeds"><i class="icon-info"></i> Click here to visit the old strains page.</NuxtLink>
      </template>  
      <template v-else>
        <h1>
          {{ $t('grow_strains_view_title') }}      
        </h1>        
        <NuxtLink to="/seeds"><i class="icon-info"></i> Click here to visit the old strains page.</NuxtLink>
      </template>  



      <GeneralShowMore
        v-if="categoryShort"
        :desktop-max-height="1000"
        :mobile-max-height="70" 
      >
        <div class="desc" v-html="categoryShort"></div> 
      </GeneralShowMore>

    </div>




    <GeneralFilterTabsBar
      :exists-keyword-search="true"
      :filter-categories="EStrainsFilters"
      v-model:selected="tagsSelected"
      v-model:keyword="keyword"
    />

    <div class="fltbar">

      <div class="flttags">
        <div class="total">
          {{ total }} strains
        </div>

        <GeneralFilterTabsSelected
          :filter-categories="EStrainsFilters"
          v-model:selected="tagsSelected"
          v-model:keyword="keyword"
        />

      </div>
          

      <div class="srt">
        <UiSortV2
          @choose="chooseSort"
          :val="sort"    
          :options="[
            {id:'featured', name: 'Featured', icon: 'desc'},
            {id:'az_desc', name: $t('universal_sort_az'), icon: 'desc'},
            {id:'az_asc', name: $t('universal_sort_az'), icon: 'asc'},
            {id:'rate_desc', name: $t('universal_sort_rating'), icon: 'desc'},
            {id:'rate_asc', name: $t('universal_sort_rating'), icon: 'asc'},
            {id:'gplant_desc', name: $t('universal_sort_gplant'), icon: 'desc'},
            {id:'gplant_asc', name: $t('universal_sort_gplant'), icon: 'asc'},
            {id:'harvests_desc', name: $t('universal_sort_harvests'), icon: 'desc'},
            {id:'harvests_asc', name: $t('universal_sort_harvests'), icon: 'asc'},
            {id:'diaries_desc', name: $t('universal_sort_diaries'), icon: 'desc'},
            {id:'diaries_asc', name: $t('universal_sort_diaries'), icon: 'asc'},
          ]"
        />
      </div>
    </div>

    <div ref="elList"></div>

    <SkStrains
      v-if="strainDataStatus === 'pending' && start === 0"
      :count="limit"
    />

    <div 
      v-else-if="strainData.items?.length" 
      class="products"
    >

      <GeneralStrain
        v-for="(item, num) in strainData.items"        
        :cover-s="item.cover_s"
        :link="item.link"
        :nm="item.name"
        :thc="item.props?.seed_strain_thc"
        :cbd="item.props?.seed_strain_cbd"
        :item-stat="item.item_stat"
        :cnt-diaries="item.item_stat?.cnt_reports"
        :cnt-growers="item.item_stat?.cnt_growers"
        :cnt-harvests="item.item_stat?.cnt_harvests"
        :rate-norm="item.item_stat?.avg_rate"
        :rate="item.item_stat?.avg_rate"
      />


    </div>

    <UiEmpty
      v-else
    />



    <!-- <ClientOnly>-->
      <UiInfinityScroll 
        v-if="total > start + limit"
        :is-loading="strainDataStatus === 'pending'"
        :is-button-loading="true"
        :button-name="loadButtonName"
        @click="start = start + limit"        
        ref="elInfinityScroll"
      />
    <!-- </ClientOnly> -->



    <!-- <UiPagination
      v-model:page="page"
      :total-items="pageTotal"
      :per-page="limit"
      :max-visible-pages="3"
    /> -->



    <StrainDescription
      v-if="categoryDescription"
      :text="categoryDescription"
    />    


    <div class="to-top-btn" @click="scrollToList">
      <i class="icon-arrow-top"></i>
    </div>



  </div>

</template>

<script setup lang="ts"> 

interface StrainData {
  items: Strain[],
  items_tag: Tag[]
}

import { TagsStrains2, getTagsKeys, getTagsSimple } from '@/types/other'
import type { Strain } from '@/types/strain'
import type { Tag } from '@/types/other'
import { EStrainsFilters, RStrainCategoriesLinks } from '@/types/strain'
import {strains} from '~/i18n/strains/en.js'
import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const route = useRoute();
const isEnded = ref(false)
const tags = ref(structuredClone(TagsStrains2))
const {$api, $adv, $ga, $patcher} = useNuxtApp()
const elInfinityScroll = ref(null)
const elList = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: false
  },
  pageCategoryId: {
    type: String,
    required: false
  },
  pageCategoryVal: {
    type: String,
    required: false
  },
})

const start = ref(0)
const limit = ref(40)
// const page = ref(1)
const query = ref('')
const sort = ref('featured')
const tagscount = ref([])
const tagsSelected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const list = ref([])
const keyword = ref(route.query['keyword']?.length ? route.query['keyword'] : '')

const tagsPermanent = ref([])
const categoryId = ref(props.pageCategoryId)
const categoryVal = ref(props.pageCategoryVal)

if(categoryId.value && categoryVal.value){
  tagsSelected.value.push(categoryId.value + ':' + categoryVal.value);
}

const loadButtonName = computed(() => {
  return 'View More';
  return 'Load more ' + (total.value - start.value - limit.value > limit.value ? limit.value : (total.value - start.value - limit.value)) + ' of ' + (total.value - start.value - limit.value);
})

const total = computed(() => {
    return strainData.value?.items_tag?.filter((item) => item.id == 'all')[0]?.count || 0;
})

const clearPagination = function(){
  start.value = 0;  
  // page.value = 1;
}


const unchooseTags = function(selected: string){  
  clearCategory();
  tagsSelected.value = tagsSelected.value.filter((item: string) => item != selected);
}     

const handleCategoriesSelect = function(cat){
  clearCategory();
  clearPagination();  
  tagsSelected.value = [cat];
}

const clearTags = function(){
  tagsSelected.value = [];  
  // clearCategory();
  $ga.eventGA('explore', 'tag', getTagsSimple(tags.value, tagsSelected.value));
}

const chooseTags = function(selected){          
  tagsSelected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', getTagsSimple(tags.value, tagsSelected.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  // clearPagination();  
}
 
const changeKeyword = function(text) {  
  start.value = 0;
  // page.value = 1;
  keyword.value = text;
}  


const clearCategory = function(){
  categoryId.value = null;
  categoryVal.value = null;
  // clearPagination();
}

const isCategorySelected = computed(() => {
  if(!categoryId.value && !categoryVal.value)
    return false;
  if(tagsSelected.value.indexOf(categoryId.value + ':' + categoryVal.value) != -1)
    return true;
})

const loadData = async function() {  
  const response = await $api.getStrains({
    start: start.value,
    limit: limit.value,
    keyword: keyword.value,
    sortable: sort.value,
    tagsall: getTagsKeys(tags.value), 
    tags: getTagsSimple(tags.value, tagsSelected.value),
    tagsper: getTagsSimple(tags.value, tagsPermanent.value)
  });
  return response;  
}



const { status: strainDataStatus, refresh: refreshData, data: strainData } = await useLazyAsyncData('strainData', async () => await loadData() as StrainData)

const scrollToList = () => {
  if (!elList.value) return
  
  const topOffset = elList.value.getBoundingClientRect().top + window.scrollY - 60

  window.scrollTo({
    top: topOffset,
    behavior: 'smooth'
  })
}

const generatePath = (patcher) => {
  let url = '';

  console.log('generatePath');

  if(patcher.tags.length > 1 || patcher.isOnlyQuery){        
    query.tags = patcher.tags.join(',');
    url = '/' + patcher.path + '';
    
    // console.log('url', url);

  }else if(patcher.tags.length === 1 && patcher.tags.indexOf('all') == -1){   
    
    console.log('patcher.tags[0]');
    console.log(patcher.tags[0]);
    
    url = RStrainCategoriesLinks[patcher.tags[0]];
    
  }else if(patcher.tags.length && patcher.tags.indexOf('all') == -1){
    url = '/' + patcher.path + '/' + patcher.tags.join(',');
  }else{
    url = '/' + patcher.path + '';
  }

  return url;
}


const changeCetegorySelected = (new_tagsSelected) => {
  if(new_tagsSelected.length == 1 && new_tagsSelected[0] != 'all:all'){
    let cat = new_tagsSelected[0].split(':');
    categoryId.value = cat[0];
    categoryVal.value = cat[1];
  }else{
    categoryId.value = null;
    categoryVal.value = null;
  }
}



const categoryName = computed(() => {
  let el = strains?.find(item => item.id == categoryId.value + ':' + categoryVal.value);
  return el?.name ?? '';
})

const categoryH1 = computed(() => {
  let el = strains?.find(item => item.id == categoryId.value + ':' + categoryVal.value);
  return el?.h1 ?? '';
})

const categoryShort = computed(() => {
  let el = strains?.find(item => item.id == categoryId.value + ':' + categoryVal.value);
  return el?.short??''
})

const categoryDescription = computed(() => {
  let el = strains?.find(item => item.id == categoryId.value + ':' + categoryVal.value);
  return el?.description??''
})

const pageTotal = computed(() => {
  return strainData.value?.items_tag?.filter((item) => item.id === 'all')[0]?.count || 0;
})


watchArray([sort, tagsSelected, keyword], async ([new_sort, new_tagsSelected, new_keyword], added, removed) => {
  
  $patcher.change('strains', {
    tags: new_tagsSelected,
    keyword: new_keyword,
    sort: new_sort,
    list: EStrainsFilters
  }, generatePath);

  changeCetegorySelected(new_tagsSelected);

  
  // page.value = 1
  start.value = 0;
  strainData.value.items = [];

  refreshData();



}, {deep: true})


watchArray([start], async ([newStart], added, removed) => {
   
  // scrollToList();

  strainDataStatus.value = 'pending';
  var dt = await loadData();


  if(newStart === 1){
    strainData.value.items = [];
    isEnded.value = false;
  }
    
  if(dt.length === 0)
    isEnded.value = true;  

  strainData.value.items = [...strainData.value.items, ...dt.items]
  if(newStart === 0)
    strainData.value.items_tag = dt.items_tag
  strainDataStatus.value = 'success';

  
  // $adv.apply();

}, {deep: true})



// useIntersectionObserver(
//   elInfinityScroll,
//   ([{isIntersecting}]) => {
//     if(isIntersecting){
//       console.log('infinity scroll event');        
//       if(strainDataStatus.value !== 'loading' && !isEnded.value)
//         start.value = start.value + limit.value;
//     }
//   },
//   { distance: 10 }
// )


if(categoryId.value && categoryVal.value){
  useHead(useNuxtApp().$head.getStrainsCategory(categoryId.value, categoryName.value));
}else{
  useHead(useNuxtApp().$head.getStrains());
}

 

</script>

<style scoped>

.products{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;
}
h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.headerow .desc:deep(p){
  margin-top: 0;
  font-size: 1rem;
}

.headerow .desc:deep(a){
  color: var(--un-firm);
  text-decoration: underline;
}

.srt{
    display: flex;
    justify-content: flex-end;
    margin-left: auto;    
}


.fltbar{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

}

.fltbar .flttags{
    display: flex;
    align-items: center;
    gap: 10px;
}

.fltbar .flttags .total{

    font-weight: 600;
}
 

.fltbar .flttags .total:before{
    margin-right: 5px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
  .products{
    margin-top: 0rem;
  }
}


/* to top */

.to-top-btn{
  margin: 0rem auto;
  padding: 1rem;
  display: flex;
  width: fit-content;
  cursor: pointer;
}
 
</style>
