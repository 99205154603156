<template>
    <div class="search">

      <input
        type="text"
        ref="inputEl"
        v-model="localKeyword"
        :placeholder="placeholder"
      />
      

      <i class="icon-close" 
         @click="remove" 
      ></i>
  
    </div>
  </template>
  
  
<script lang="ts" setup>

import debounce from 'lodash/debounce'


const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  keyword: {
    type: String,
    default: '',
  },
})

const emits = defineEmits<{
  (e: 'update:keyword', value: string): void
  (e: 'close'): void
}>()

const localKeyword = ref<string>(props.keyword)

const inputEl = ref<HTMLInputElement | null>(null)

watch(
  () => props.keyword,
  (newVal) => {
    if (newVal !== localKeyword.value) {
      localKeyword.value = newVal
    }
  }
)


const emitSearch = debounce(() => {
  emits('update:keyword', localKeyword.value)
}, 300)


watch(localKeyword, () => {
  emitSearch()
})


function focusInput() {
  if (inputEl.value) {
    nextTick(() => {
      inputEl.value?.focus()
    })
  }
}


function remove() {
  localKeyword.value = ''
  emits('update:keyword', localKeyword.value)
  emits('close')
}


defineExpose({
  focusInput,
})

</script>
  
  <style scoped>
  .search {
    position: relative;
    max-width: 200px;
    width: 100%;
  }
  .search input {
    border-radius: 30px;
    padding: 0.4rem 1.5rem 0.4rem 0.6rem;
    min-width: 130px;
  }
  .search .icon-close,
  .search .icon-search {
    position: absolute;
    top: 0;
    display: flex;
    width: 26px;
    height: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: gray;
  }
  .search .icon-close {
    right: 4px;
  }
  .search .icon-search {
    right: 4px;
  }

  @container pb (max-width: 650px) {
    .search {
      max-width: 100%;
    }
  }
  </style>