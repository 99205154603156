<template>
  
  <div class="ls">
    <div 
      v-for="i in props.count"
      :key="i"
      class="rw skl"></div>
  </div>
 
</template>


<script setup lang="ts">

interface PageProps {
  count: number
}

const props = withDefaults(defineProps<PageProps>(), {
  count: 10
})
 
</script>


<style scoped>

.ls{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
}
.rw{  
  aspect-ratio: 142/241;
  width: calc(100%/7 - 25px);
} 

 


@container pb (max-width: 1200px) {
   .rw {
     width: calc(100%/6 - 25px);
   }
 }

 @container pb (max-width: 1100px) {

   .rw {
     width: calc(100%/5 - 25px);
   }
 }

 @container pb (max-width: 1000px) {
   .rw {
     width: calc(100%/5 - 25px);
   }
 }

 @container pb (max-width: 650px) {
   .rw {
     width: calc(100%/3 - 25px);

   }
 }

 @container pb (max-width: 550px) {
   .rw {
     width: calc(50% - 25px);
   }
 }



</style>
